<template>
  <div class="pt-4 px-4">
    <div class="mb-3 d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h2>New Backfill Job</h2>
        <button 
          class="btn btn-sm btn-primary ms-2" 
          @click="testConnection"
          :disabled="testingConnection"
        >
          {{ testingConnection ? 'Testing...' : 'Test Connection' }}
        </button>
      </div>
    </div>

    <div class="mb-3">
      <label class="form-label">Customer</label>
      <select class="form-select" v-model="job.customerId">
        <option v-for="customer in model.customers" :value="customer.customerId">
          {{ customer.customerName }}
        </option>
      </select>
    </div>

    <div class="mb-3">
      <label class="form-label">Market Name</label>
      <input 
        v-model="job.marketName" 
        type="text" 
        class="form-control"
        placeholder="Enter market name"
      >
    </div>

    <div class="mb-3">
      <label class="form-label">Job Type</label>
      <select class="form-select" v-model="job.jobType">
        <option value="">Select job type</option>
        <option value="DIGITAL TRANSIT">DIGITAL TRANSIT</option>
        <option value="DIGITAL STATIONARY">DIGITAL STATIONARY</option>
        <option value="STATIC TRANSIT">STATIC TRANSIT</option>
      </select>
    </div>

    <div class="mb-3" v-if="isTransitJob">
      <label class="form-label">Provider</label>
      <select class="form-select" v-model="job.provider">
        <option value="">Select provider</option>
        <option v-for="provider in model.providers" :key="provider.providerId" :value="provider.providerId">
          {{ provider.providerName }}
        </option>
      </select>
    </div>

    <div class="mb-3">
      <label class="form-label">Files</label>
      <input 
        type="file" 
        class="form-control" 
        @change="handleFileUpload" 
        multiple
        :accept="acceptedFileTypes"
      >
    </div>

    <div class="mb-3">
      <button 
        type="button" 
        class="btn btn-secondary" 
        style="min-width: 150px;" 
        @click="onSaveClick"
        :disabled="loading"
      >
        {{ loading ? 'Saving...' : 'Save' }}
      </button>

      <router-link :to="{ name: 'pipeline' }" class="btn btn-light ms-2">
        Cancel
      </router-link>
    </div>
  </div>
</template>

<script>
import BackfillService from "@/services/BackfillService";
import AppUtil from "@/utils/AppUtil";
import streetmetrics from "@/model";

export default {
  name: 'BackfillJobForm',
  data() {
    return {
      model: streetmetrics.model,
      service: new BackfillService(),
      loading: false,
      testingConnection: false,
      job: {
        customerId: null,
        marketName: '',
        jobType: '',
        provider: '',
        files: []
      }
    }
  },
  computed: {
    isTransitJob() {
      return this.job.jobType === 'DIGITAL TRANSIT' || this.job.jobType === 'STATIC TRANSIT';
    },
    acceptedFileTypes() {
      return this.job.jobType === 'DIGITAL STATIONARY' 
        ? '.gz' 
        : '.csv';
    }
  },
  methods: {
    async testConnection() {
      this.testingConnection = true;
      try {
        const response = await this.service.testConnection();
        this.model.toast.background = 'bg-green';
        this.model.toast.message = `Connection successful: ${response}`;
      } catch (error) {
        console.error('Connection test failed:', error);
        this.model.toast.background = 'bg-red';
        this.model.toast.message = 'Failed to connect to backfill service';
      } finally {
        this.testingConnection = false;
      }
    },

    handleFileUpload(event) {
      const files = Array.from(event.target.files);
      
      // Validate file types based on job type
      const invalidFiles = files.filter(file => {
        const ext = file.name.split('.').pop().toLowerCase();
        if (this.job.jobType === 'DIGITAL STATIONARY') {
          return ext !== 'gz';
        }
        return ext !== 'csv';
      });

      if (invalidFiles.length > 0) {
        this.model.toast.background = 'bg-red';
        this.model.toast.message = `Invalid file type(s). ${this.job.jobType === 'DIGITAL STATIONARY' ? 'Only .gz files are accepted' : 'Only .csv files are accepted'}`;
        event.target.value = ''; // Clear the file input
        return;
      }

      this.job.files = files;
    },

    async onSaveClick() {
      if (!this.validateForm()) {
        return;
      }

      this.loading = true;
      
      try {
        const formData = new FormData();
        this.job.files.forEach(file => {
          formData.append('files', file);
        });
        formData.append('customer', this.job.customerId);
        formData.append('marketName', this.job.marketName);
        formData.append('jobType', this.job.jobType);
        if (this.isTransitJob) {
          formData.append('provider', this.job.provider);
        }

        await this.service.uploadFiles(formData);

        this.model.toast.background = 'bg-green';
        this.model.toast.message = 'Backfill job submitted successfully';
        this.$router.push({ name: 'pipeline' });
      } catch (error) {
        console.error('Error submitting backfill job:', error);
        this.model.toast.background = 'bg-red';
        this.model.toast.message = 'There was an error trying to submit the backfill job. Please try again later.';
      } finally {
        this.loading = false;
      }
    },

    validateForm() {
      if (!this.job.customerId) {
        this.model.toast.background = 'bg-red';
        this.model.toast.message = 'Please select a customer';
        return false;
      }
      if (!this.job.marketName) {
        this.model.toast.background = 'bg-red';
        this.model.toast.message = 'Please enter a market name';
        return false;
      }
      if (!this.job.jobType) {
        this.model.toast.background = 'bg-red';
        this.model.toast.message = 'Please enter a job type';
        return false;
      }
      if (this.isTransitJob && !this.job.provider) {
        this.model.toast.background = 'bg-red';
        this.model.toast.message = 'Please select a provider';
        return false;
      }
      if (!this.job.files.length) {
        this.model.toast.background = 'bg-red';
        this.model.toast.message = 'Please select at least one file';
        return false;
      }
      return true;
    }
  }
}
</script>
